<template>
  <section>
    <TopBanner
      title="Finance HardShips"
      :src="require('@/assets/images/banners/banner_contact.jpg')"
    />
    <v-container class="content-container">
      <div class="text-center">
        <p class="primary--text text-h5 text-sm-h3 font-weight-bold">
          Financial hardships
        </p>
      </div>
      <div class="font-weight-regular text-body-1">
        <div>
          If you have recently experienced a financial hardship and can&#39;t
          make your mortgage payment,
          {{ $store.state.company.name }} has several financial hardship workout
          options available that may help you.
        </div>

        <div class="my-2">
          <div class="my-2">
            To begin a financial hardship workout, you must complete a Mortgage
            Assistance Application

            <a
              href="https://newwavelending.com//web_resources/FORMS/Financial_Hardships_Form_710.pdf"
              target="_blank"
            >
              (Fannie Mae/Freddie Mac Form 710)
            </a>
            .
          </div>
        </div>

        <div>
          Please print and complete the Mortgage Assistance Application, and
          mail with any supporting documents to
        </div>
        <br />
        <div class="text-center text-h5">
          <div class="my-2">
            {{ $store.state.company.name }}
          </div>
          <div class="my-2">Servicing Department</div>
          <div class="my-2">
            {{
              `${$store.state.company.street1}, ${$store.state.company.city}, ${$store.state.company.state} ${$store.state.company.zip}`
            }}
          </div>
        </div>
        <br />

        <div class="mt-4">
          If you have any questions, please call us at 866-850-8880,
          Monday-Friday 8:30 a.m.- 5:30 p.m. PT. To find a HUD-approved housing
          counseling agency, please call HUD at 800-569-4287 or visit the HUD
          counseling Website
        </div>
        <div>
          <a
            href="https://hud4.my.site.com/housingcounseling/s/?language=en_US"
            target="_blank"
            >https://hud4.my.site.com/housingcounseling</a
          >.
        </div>
      </div>
    </v-container>
    <PdfViewer :src="targetPdfPath" />
    <Footer />
  </section>
</template>

<script type="text/javascript">
import TopBanner from "@/components/TopBanner";
import Footer from "@/views/layouts/Footer";
import PdfViewer from "@/components/PdfViewer";

export default {
  components: {
    TopBanner,
    PdfViewer,
    Footer,
  },
  mounted() {
    // At this point, the child GmapMap has been mounted, but
    // its map has not been initialized.
    // Therefore we need to write mapRef.$mapPromise.then(() => ...)
    // this.$refs.mapRef.$mapPromise.then((map) => {
    //   console.log(map);
    // });
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
